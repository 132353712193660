import React from 'react'
import Navbar from '../../elements/navbar/Navbar';

const index = () => {
    return (
        <>
            <Navbar />
            <div className='container-fluid' style={{ marginTop: "115px" }}>
                <h5 className='w-100 text-center'>DK Global Admin Dashboard</h5>
            </div>
        </>
    )
}

export default index