import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import LoginRoute from "./components/hocs/LoginRoute";
import PrivateRoute from "./components/hocs/PrivateRoute";
import CheckLogin from "./components/hocs/checkLogin";

import './App.css';
import MainDashboard from './components/pages/dashboard'
// START MODULE FOR TOOLS
//import BackedValues from './components/pages/tools/backendvalues'
import BkValues from "./components/pages/tools/bkvalues/BkValues";
// END MODULE FOR TOOLS
// START MODULE FOR SETTINGS
import UsersManagement from './components/pages/settings/usermanagement/UsersManagement'
//END MODLUE FOR SETTINGS


function App() {
  return (
    <React.Fragment>
      <div className="App">
        <Switch>
          <Route exact path="/" component={MainDashboard} />
          {/* Start Module for Tools */}
          <Route path="/tools/bkvalues" component={BkValues} />
          {/* End Module for Tools */}
          {/* Start Module for Settings */}
          <PrivateRoute path="/settings/users" component={UsersManagement} />
          {/* <PrivateRoute path="/settings/users" component={UsersManagement} /> */}
          {/* End Module for Tools */}
          <Route path="/check-auth/:slug" component={CheckLogin} />
        </Switch>
      </div>
    </React.Fragment>
  );
}

export default App;
