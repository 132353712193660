import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { SortableContainer, SortableElement, sortableHandle } from "react-sortable-hoc";
import { useDispatch, useSelector } from "react-redux";
import { arrayMoveImmutable } from 'array-move';
import Submit from "../../../../../src/components/ui/submitButton";
import { valuesList, reorderValues, updateValue, deleteBkValue, clearState } from "../../../../slice/backendValues/backendValuesSlice";
import { showSuccess, showError, clearMessage } from "../../../../slice/utils/message/messageSlice";

const DragHandle = sortableHandle(() => <span className="dk-handle" style={{ backgroundColor: "#363f4c", height: "35px !important", width: "50px", textAlign: "center", lineHeight: "35px", color: "white", cursor: "all-scroll" }}><i class="fas fa-bars"></i></span>);

const SortableItem = SortableElement(({ value }) => {
    const deleteItem = (id) => {
        // console.log(id)
    }
    return (
        <React.Fragment>
            <div className="Mukul">
                <div className="dk-valueFields d-flex" key={value.id} id={value.id}>
                    <DragHandle />
                    <input type="text" style={{ background: value.bgColor, color: value.textColor, height: "35px", borderRadius: "0", border: "1px solid #ddd", boxShadow: "none !important" }} value={value.value} name="" id="" className="form-control" />
                </div>
            </div>
        </React.Fragment>
    )
})

const SortableList = SortableContainer(({ items }) => {
    return (
        <ul>
            {items.map((value, index) => (
                <SortableItem key={`item-${index}`} index={index} value={value} />
            ))}
        </ul>
    );
});


const ReorderValue = ({ cateId }) => {
    const dispatch = useDispatch();

    const [list, setList] = useState([])
    const { isDelete, isError, msg, isloading, backendValuesList, isReoder } = useSelector(state => state.backendValues)

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const reorderCategoryValues = async () => {
        setLoading(true)
        setSuccess(false)
        let reorderListArray = []
        list.map((data) => {
            reorderListArray.push(data.id)
        })

        await dispatch(reorderValues({ ids: reorderListArray, cateId }))
    }

    useEffect(() => {
        if (cateId > 0) {
            dispatch(valuesList({ categoryId: cateId }))
        }
    }, [cateId])

    useEffect(() => {
        if (!isloading) {
            setList(backendValuesList)
        }
    }, [isloading])

    useEffect(() => {
        if (isReoder) {
            setLoading(false)
            setSuccess(false)
        }
        if (isError) {
            setLoading(false)
            setSuccess(false)
        }
    }, [isReoder, isError])

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setList(arrayMoveImmutable(list, oldIndex, newIndex))
    };

    return (
        <>
            {
                (cateId > 0 && backendValuesList.length > 0) ?
                    <span onClick={handleShow} ><i class="fas fa-bars"></i></span>
                    : null
            }
            <Modal show={show} onHide={handleClose} className="dk-editCategoryMain">
                <Modal.Header closeButton>
                    <Modal.Title>Reorder Values</Modal.Title>
                </Modal.Header>
                <Modal.Body className='dkg-bc-reordervalueBody'>
                    <div className='dk-toolsModalbody'>
                        <div className='tab-content'>
                            <div className='dk-toolsValuesPanel dk-toolsReorderPanel'>
                                {
                                    <SortableList lockAxis="y" items={list} onSortEnd={onSortEnd} useDragHandle />
                                }

                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='dkg-bc-reordervalueFooter'>
                    <div className="d-flex justify-content-center dkg-submit-btnCon">
                        <Submit txt="Save Order" className="dk-toolsPanelSaveBtn" loading={loading} success={success} onClick={reorderCategoryValues} position="justify-content-center" />
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}


export default ReorderValue