import React from "react";
import { Link } from "react-router-dom";
import './navbar.scss';
import Header from '../header/Header'

const AdminNavbar = () => {
    const pathname = window.location.pathname;
    return (
        <React.Fragment>
            <Header />
            <div className="dk-navBarwrapper">
                <div className="dk-navBarMain dk-adminNavbar">
                    <ul>
                        <li>
                            <Link to="/" className={`${pathname.match('/') ? 'active' : ''}`}>Dashboard </Link>
                        </li>
                        <li className="dropdown-toggle">
                            <Link to="#" className={`${pathname.match('/') ? 'sub-active' : ''}`}>Company</Link>
                        </li>
                        <li className="dropdown-toggle">
                            <Link to="#" className={`${pathname.match('/') ? 'sub-active' : ''}`}>Jobs</Link>
                        </li>
                        <li className="dropdown-toggle">
                            <Link to="#" className={`${pathname.match('/') ? 'sub-active' : ''}`}>Specs</Link>
                        </li>
                        <li className="dropdown-toggle">
                            <Link to="#" className={`${pathname.match('/') ? 'sub-active' : ''}`}>Candidates</Link>
                        </li>
                        <li className="dropdown-toggle">
                            <Link to="#" className={`${pathname.match('/') ? 'sub-active' : ''}`}>Ads</Link>
                        </li>
                        <li className="dropdown-toggle">
                            <Link to="#/admin/accounts/summary" className={`${pathname.match('/accounts') ? 'active' : ''}`}>Accounts</Link>
                        </li>
                        <li className="dropdown-toggle">
                            <Link to="#/admin/training/summary" className={`${pathname.match('/training') ? 'active' : ''}`}>Training</Link>
                        </li>
                        <li className="dropdown-toggle">
                            <Link to="#" className={`${pathname.match('/') ? 'sub-active' : ''}`}>Knowledge</Link>
                        </li>
                        <li className="dropdown-toggle">
                            <Link to="/tools/bkvalues" className={`${pathname.match('/tools') ? 'active' : ''}`}>Tools</Link>
                        </li>
                        <li className="dropdown-toggle">
                            <Link to="/settings/users" className={`${pathname.match('/settings') ? 'active' : ''}`}>Settings</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </React.Fragment>
    );
}

export default AdminNavbar;
