import React from 'react';
import { Link } from "react-router-dom";
import AdminLogo from "../../../assets/images/dkadmin-logo.png";
import Deepak from '../../../assets/images/deepak.png'
import './header.scss';
import Dropdown from 'react-bootstrap/Dropdown'
const AdminHeader = () => {
    return (
        <React.Fragment>
            <div className="dk-adminMainHeader">
                <div className="dk-adminNavbar">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="dk-adminLogo">
                            <Link to="/">
                                <img src={AdminLogo} className="img-fluid" alt="" />
                            </Link>
                        </div>
                        <div className="dk-nav-title">
                            <span>ADMIN PANEL - DK GLOBAL ADMIN</span>
                        </div>
                        <div className="dk-nav-profile">
                            <Dropdown>
                                <div className="dropdown-img">
                                    <img src={Deepak} className="img-fluid" alt="" />
                                </div>
                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="https://www.dkglobaladmin.com/"><i class="fas fa-sign-out-alt mr-2"></i> Sign Out</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default AdminHeader;
